import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Layout/SEO"
import FAQ from "../components/FAQ"

export default  () => {
  return(
  <Layout seoProps={{title: "Frequently Asked Questions"}}>
    <SEO />
    <FAQ />
  </Layout>
  )
  }

