import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { MinusIcon, PlusIcon, BreakpointMobile } from '../Layout/GlobalStyle';

const Container = styled.div`
    min-height: 35px;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #ddd;
    transition: 0.2s ease-in-out;

    .fact-answer{
        font-family: var(--Font2);
    }
    .faq-heading{
        font-size: 18px;
        padding-top: 5px;
        display: flex;   
        cursor: pointer;
        font-weight: 600;
        font-family: var(--Font1);
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        line-height: 18px;
        transition: 0.2s ease-in-out;
        user-select: none;
        opacity: 0.6;
        ${props => props.open? 'opacity: 1;' : ''};

        :hover{
            opacity: 1;
        }
    }

    .call-to-action-link{
        color: var(--Color2);
        font-size: inherit;
        text-decoration: underline;
    }

    .faq-button{
        background: transparent;
        border: 0px solid transparent;
        width: 30px;
        height: 100%;
        font-family: var(--Font1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;

        svg{
            width: 30px;
            height: 30px;
        }
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        .faq-button{
            display: none;
        }
        .faq-heading{
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: medium;
        }

    }

    :last-of-type{
        border-bottom: solid 1px transparent;

    }
`

const Content = styled.div`
    display: block;
    height: 0px;
    transition: 0.2s ease-in-out;
    ${props => props.open? 'height: initial;' : 'overflow: hidden; height: 0px;'};
    font-size: 15px;

    p{
        
        padding-top: 0px;
        font-size: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: var(--Font2);
    }

    .content-wrapper{
        padding-bottom: 25px;
        padding-left: 40px;

    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        .content-wrapper{
            padding-left: 0px;

        }


    }
`

 function FactItem(props) {
     let [open, setOpen] = useState(false)
     let {fact} = props;

    return (
        <Container open={open}>
            <div onClick={() => setOpen(!open)} className="faq-heading">

            <button className="faq-button">{open? <MinusIcon /> : <PlusIcon />}</button>
                <div className="faq-question">
                    {fact.question}
                </div>
            </div>
            <Content open={open}>
                <div className="content-wrapper">

                <p className="fact-answer">
                    {fact.answer}
                </p>
                {fact.call_to_action_link && !fact.outbound?
                <Link to={fact.call_to_action_link}>
                    <a className="call-to-action-link">
                        {fact.call_to_action_text} 
                    </a>
           
                </Link>    
                : fact.call_to_action_text && fact.call_to_action_link && fact.outbound? 
                    <OutboundLink target={"_blank"} href={fact.call_to_action_link.toString()}>
                    <div className="call-to-action-link">
                        {fact.call_to_action_text} 
                    </div>
                    </OutboundLink> :
                    
                ""}
                </div>

            </Content>
        </Container>
    )
}

FactItem.defaultProps = {
    fact: {
        outbound: false,
        question: "",
        answer: "",
        category: "",
        call_to_action_link: "",
        call_to_action_text: ""
    }
}

export default FactItem;

