import React from 'react'
import styled from 'styled-components'
import { PaddingLeftRightDesktop, PaddingTopBottomRem, BreakpointMobile, BreakpointTablet, PaddingTopBottomRemMobile, BreakpointSmallMobile } from '../Layout/GlobalStyle'
import FactItem from './FactItem'
import {CONTACT} from '../Layout/routes'

const FactSection = styled.section`
    h2{
        margin-bottom: 0px;
    }

    .fact-section-h2{
        font-family: var(--Font1);
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        font-size: medium;
    }
`

const Container = styled.div`
    padding-top:  ${PaddingTopBottomRem};
    padding-bottom: 40px;

    .hero{
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1{
            font-family: var(--Font1NotCondensed);
            padding-left: ${PaddingLeftRightDesktop};
            padding-right: ${PaddingLeftRightDesktop};
            text-align: center;
            font-weight: lighter;
            margin-bottom: 0px;
        }

        p{
            width: 100%;
            max-width: 400px;
            font-size: medium;
            font-family: var(--Font2);
            text-align: center;
        }


    }

    .content{
        margin: auto;
        max-width: var(--PageMaxWidth);
       
    }


    @media only screen and (max-width: ${BreakpointTablet + 'px'}){
        .hero{

            h1{
                font-size: xx-large;
                line-height: 40px;

            }

            p{
                width: 90%;
            }
        }
    }


    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        .hero{
            height: initial;
            height: 50vh;

            /* h1{
                font-size: x-large;
                line-height: 35px;

            } */

            p{
                width: 90%;
            }
        }
    }

`

export default function FAQ() {
    let generalFacts = [
        {
            question: "How much does your service cost?",
            category: "general_information",
            answer: "Nothing, our service is free",
            outbound: false,
        }
    ]

    let renderGeneralFacts = generalFacts.map((item, index) => {
        return <FactItem fact={item} key={index} />
    })
    return (
        <Container>
            <div className="hero">
                <h1>Frequently Asked Questions</h1>
                <p>
                    We answer all your burning questions about FindAnHerb, and some you probably never even thought to ask.


                </p>

            </div>
            <div className="content">
                <FactSection>
                    <h2 className={"fact-section-h2"}>
                        General
                    </h2>
                    {renderGeneralFacts}
                </FactSection>

            </div>

            
            
        </Container>
    )
}
